<template>
  <div class="bookshelf">
    <div class="covers flex justify-center flex-column space-x-2 md:space-x-12">
      <div
        class="document text-center shadow-lg rounded-lg p-2 my-2"
        v-for="book in books"
        :key="book.document_id"
      >
        <router-link
          :to="{
            name: 'OpenEventDocumentViewer',
            params: {
              id: book.document_id,
            },
          }"
          class="text-gray-600 text-xs md:text-xl font-medium"
        >
          <div class="thumb rounded md:rounded-xl relative">
            <img
              class="h-20 md:w-40 md:h-56 rounded"
              :src="book.thumbnail"
              :alt="`Thumbnail for ${book.title}`"
            />
          </div>
          <p class="mt-2">
            {{ book.title }}
          </p>
        </router-link>
      </div>
    </div>
    <img
      class="shelf-img w-full"
      :src="require('@/assets/images/sample-catalog/shelf_metal.png')"
    />
  </div>
</template>

<script>
export default {
  name: "BookShelf",
  props: {
    books: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
