<template>
  <div
    class="bookshelves border p-8 bg-nColorFillGray border border-nColorStrokeGray rounded-xl relative"
  >
    <BookShelf
      v-for="(document, i) in documentsArray"
      :books="document"
      :key="i"
    />
  </div>
</template>

<script>
import BookShelf from "./BookShelf.vue";

export default {
  name: "DLContainer",
  components: { BookShelf },
  props: {
    documents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      documentList: null,
      booksInShelf: this.isMobile() ? 3 : 4,
      documentsArray: [],
    };
  },
  created() {
    this.documentList = [...this.documents];

    this.spliceDocuments(this.documentList);
  },
  computed: {},
  methods: {
    spliceDocuments(documentList) {
      while (documentList.length) {
        this.documentsArray.push(documentList.splice(0, this.booksInShelf));
      }
    },
    isMobile() {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.top-108 {
  top: 27rem;
}
</style>
